import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TextArea = makeShortcode("TextArea");
const TextColumn = makeShortcode("TextColumn");
const NavigationTiles = makeShortcode("NavigationTiles");
const NavigationTile = makeShortcode("NavigationTile");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <TextArea mdxType="TextArea">
      <TextColumn mdxType="TextColumn">
        <h1>{`Portfolio`}</h1>
      </TextColumn>
    </TextArea>
    <NavigationTiles mdxType="NavigationTiles">
      <NavigationTile title="Giraffe About Town" linkPage="/portfolio/giraffe-about-town" imagePath="portfolio/giraffe-about-town/giraffe-about-town-thumb.jpg" imageAlt="Giraffe About Town" mdxType="NavigationTile" />
      <NavigationTile title="We're Not Scavengers" linkPage="/portfolio/were-not-scavengers" imagePath="portfolio/were-not-scavengers/were-not-scavengers-thumb.jpg" imageAlt="We're Not Scavengers" mdxType="NavigationTile" />
      <NavigationTile title="Novels Reimagined" linkPage="/portfolio/novels-reimagined" imagePath="portfolio/novels-reimagined/novels-reimagined-thumb.jpg" imageAlt="Novels Reimagined" mdxType="NavigationTile" />
    </NavigationTiles>
    <NavigationTiles mdxType="NavigationTiles">
      <NavigationTile title="Golden Snub-Nosed" linkPage="/portfolio/golden-snub-nosed" imagePath="portfolio/golden-snub-nosed/illustrations-golden-snub-nosed.jpg" imageAlt="Golden Snub-Nosed" mdxType="NavigationTile" />
      <NavigationTile title="Prop Box" linkPage="/portfolio/prop-box" imagePath="portfolio/prop-box/university-prop-box.jpg" imageAlt="Prop Box" mdxType="NavigationTile" />
      <NavigationTile title="Naturalis" linkPage="/portfolio/naturalis" imagePath="portfolio/naturalis/university-naturalis.jpg" imageAlt="Naturalis" mdxType="NavigationTile" />
    </NavigationTiles>
    <NavigationTiles mdxType="NavigationTiles">
      <NavigationTile title="Exhibition" linkPage="/portfolio/exhibition" imagePath="portfolio/exhibition/university-exhibition.jpg" imageAlt="Exhibition" mdxType="NavigationTile" />
      <NavigationTile title="European Bee Eater" linkPage="/portfolio/european-bee-eater" imagePath="portfolio/european-bee-eater/illustrations-european-bee-eater.jpg" imageAlt="European Bee Eater" mdxType="NavigationTile" />
      <NavigationTile title="It Happened Here" linkPage="/portfolio/it-happened-here" imagePath="portfolio/it-happened-here/personal-it-happened-here.jpg" imageAlt="It Happened Here" mdxType="NavigationTile" />
    </NavigationTiles>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      